<template>
  <div class="ReactModalPortal error-modal">
    <div class="ReactModal__Overlay ReactModal__Overlay--after-open" class-open="ReactModal__Overlay--after-open"
         style="position: fixed; top: 0px; left: 0px; right: 0px; bottom: 0px; background-color: rgba(255, 255, 255, 0.75);"
         aria-modal="true">
      <div
        class="ReactModal__Content overlay xs-full-height xs-full-width xs-mb-0 xs-flex xs-items-center ReactModal__Content--after-open"
        class-open="ReactModal__Content--after-open" tabindex="-1" aria-label="DelayedOrderModal">
        <div class="xs-overflow-auto" style="width: 100%; height: 100%;">
          <button class="icon-close--white xs-p-20 xs-absolute xs-r-20 xs-t-10 close-error-popup"
                  @click="$emit('close')"></button>
          <div class="xs-pt-60 xs-px-40 xs-text-white xs-text-center">
            <img src="../../assets/images/icons/sorry-icon.svg" width="100" class="gingle-shake xs-my-40">
            <p>
              <span v-if="message" class="error-message bold"
                    style="font-size: 20px;">{{message}}</span>
              <span v-else class="error-message bold"
                    style="font-size: 20px;" v-html="$t('modal.advance_order_message')"></span>
            </p>
            <p>
            </p></div>

          <div class="xs-px-20 xs-py-20 xs-b-0 xs-l-0 xs-r-0 xs-fill-black-70 md-static md-fill-none">
            <button class="button button--green xs-text-center button-bottom" @click="advanceOrderNow(),$emit('close')">
              <span>{{$t('modal.advance_order_now')}}</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ASAPPopup from '../popup/OrderASAP.vue'

export default {
  props: ['message', 'outletId', 'outletDataZip', 'outletDataLocalization', 'orderType'],
  methods: {
    /**
     * Trigger advance order popup
    * */
    advanceOrderNow: function () {
      this.$emit('close')
      this.$modal.show(ASAPPopup, {outletId: this.outletId, orderType: this.orderType, outletDataZip: this.outletDataZip, outletDataLocalization: this.outletDataLocalization})
    }
  }
}
</script>
<style scoped>

</style>
