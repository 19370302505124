<template>
  <div class="modal-content modal-maps">
    <div class>
      <button
        @click="$emit('close')"
        type="button"
        data-dismiss="modal"
        aria-label="Close"
        class="close"
        style="color: #fff;width: 35px; height: 35px;opacity: .6; border-radius: 35px; font-size: 35px; background: black;position: absolute; top: 30px;  z-index: 9999; right: 20px"
      >
        <span aria-hidden="true">×</span>
      </button>
      <div>
        <div class="form-group mapAddress map-input-shadow" data-synth="mapAddress" style="border-radius:0.5em;">
          <input
            id="mapLocation"
            data-synth="map-location"
            type="text"
            class="form-control"
            v-model="searchAutoComplete"
            @click="selectAllAddressSearchText"
          />
        </div>
        <div v-if="predictedPlaces.length > 0" class="suggestion-wrapper pl-10 pr-10">
          <div
            v-for="(prediction, index) in predictedPlaces"
            :key="index"
            class="suggestion-item xs-p-10"
            data-synth="suggestion-item"
          >
            <div v-on:click="selectSuggestedAddress(prediction)">
              <span class="h4 bold">
                {{
                prediction.structured_formatting.main_text
                }}
              </span>
              <div>{{ prediction.structured_formatting.secondary_text }}</div>
            </div>
          </div>
        </div>
        <div id="mapLocalization" class="mt5"></div>
      </div>
      <!-- <div class="p10 hidden" style="position: relative" > -->
      <!-- <vueper-slides  :autoplay="false"  ref="myCarouselMap" :touchable="true" :bullets="false" class="no-shadow ex--center-mode" :dragging-distance="70" @slide="logEvents" >
          <vueper-slide v-for="(outlet,i) in outletList" :key="i" v-bind:class="classMarginLeft(i)">
            <div slot="slideContent">
              <div  class="text-left ">
                <h4><strong> <span class="text-capitalize">{{outlet.name}} </span></strong></h4>
                <p>{{outlet.address}}</p>
                <p><strong><span class="t12">  {{formatNum(outlet.distance)}}Km </span></strong></p>
              </div>
            </div>
          </vueper-slide>
      </vueper-slides>-->
      <!--##############################-Silder-####################### -->
      <!--<a @click="$refs.myCarouselMap.previous()" class="left carousel-control hidden-xs" href="#" data-slide="prev"-->
      <!--style="width: 25px;">-->
      <!--<span class="glyphicon glyphicon-chevron-left"></span>-->
      <!--<span class="sr-only">{{$t('button_previous')}}</span>-->
      <!--</a>-->
      <!--<a @click="$refs.myCarouselMap.next()" class="right carousel-control hidden-xs" href="#" data-slide="next"-->
      <!--style="width: 25px;">-->
      <!--<span class="glyphicon glyphicon-chevron-right"></span>-->
      <!--<span class="sr-only">{{$t('button_next')}}</span>-->
      <!--</a>-->
      <!-- </div> -->
    </div>
  </div>
</template>
<script>
// import { VueperSlides, VueperSlide } from 'vueperslides'
// import '../../assets/css/vueperslides.min.css'
export default {
  name: 'modadddd',
  mounted() {
    var vm = this

    vm.mapDragged(vm.dragged)
    this.$root.$on('dragged', value => {
      vm.mapDragged(value)
      vm.outletCount(vm.numOutlets)
      vm.$root.$on('numOutlets', value => {
        vm.outletCount(value)
      })
    })

    this.$root.$on('onCloseMap', () => {
      vm.$emit('close')
    })
    if (window.location.href.includes('/home')) {
      for (var elem of document.getElementsByClassName('v--modal')) {
        elem.style.width = '100%'
        elem.style.maxWidth = 'none'
      }
    } else {
      elem.style.width = null
      elem.style.maxWidth = null
    }

    let pageInfo = {
      screen: {
        name: 'localisation.address-select',
        type: 'localisation'
      }
    }
    vm.eventTrackingScreenLoad(this, pageInfo)
  },
  props: ['outletList', 'dragged'],
  components: {},
  watch: {
    searchAutoComplete: function(newValue, oldValue) {
      var vm = this
      
      try {
        let autoCompleteService = new google.maps.places.AutocompleteService()
        autoCompleteService.getPlacePredictions(
          {
            input: newValue,
            offset: newValue.length,
            AUTO_COMPLETE_PATTERN: vm.AUTO_COMPLETE_PATTERN
          },
          function listentoresult(list, status) {
            if (vm.predictedPlaceSelected) {
              vm.predictedPlaces = []
              vm.predictedPlaceSelected = false
            } else {
              vm.predictedPlaces = list || []
            }
          }
        )
      } catch (error) {
        vm.predictedPlaces = []
      }
    }
  },
  data() {
    return {
      wasDragged: false,
      numOutlets: 1,
      searchAutoComplete: '',
      predictedPlaces: [],
      predictedPlaceSelected: false,
      AUTO_COMPLETE_PATTERN: {
        types: ['address'],
        componentRestrictions: { country: this.$const.COUNTRY_CODE }
      }
    }
  },
  methods: {
    // classMarginLeft: function (i) {
    //   let cl = {}
    //   switch (i) {
    //     case 0:
    //       cl = {'margin-left-10': true}
    //       break
    //     case 1:
    //       cl = {'margin-left-20': true}
    //       break
    //     case 2:
    //       cl = {'margin-left-30': true}
    //       break
    //     case 3:
    //       cl = {'margin-left-40': true}
    //       break
    //     case 4:
    //       cl = {'margin-left-50': true}
    //       break
    //     default:
    //       cl = {'margin-left-10': true}
    //   }
    //   return cl
    // },
    selectAllAddressSearchText(event) {
      var elem = event.target
      elem.setSelectionRange(0, elem.value.length)
    },
    selectSuggestedAddress(place) {
      const vm = this
      const placesService = new google.maps.places.PlacesService(
        document.getElementById('mapLocation')
      )
      placesService.getDetails(
        { reference: place.reference },
        function detailsresult(detailsResult, placesServiceStatus) {
          vm.predictedPlaces = []
          vm.predictedPlaceSelected = true
          vm.searchAutoComplete = detailsResult.formatted_address
          
          const coordinate = {
            lat: detailsResult.geometry.location.lat(),
            lng: detailsResult.geometry.location.lng()
          }
          vm.$root.$emit('drawMap', coordinate, detailsResult)
        }
      )
    },
    logEvents(eventName, params) {
      this.$root.$emit('onChangeOutlet', params.currentSlide.index)
    },
    mapDragged(dragged) {
      this.wasDragged = dragged
    },
    outletCount(count) {
      var vm = this
      vm.numOutlets = count
      if (vm.wasDragged && vm.numOutlets === 0) {
        vm.$notify({
          clean: true
        })
        vm.$notify({
          type: 'Error',
          title: vm.$t('localization.not_store_carryout')
        })
      }
    }
  }
}
</script>
<style >
#mapLocalization
  .gm-style
  > div:first-child
  > div:nth-child(3)
  > div:first-child
  > div:nth-child(4)
  > div:first-child
  > div:first-child
  > div:nth-child(3) {
  border: unset;
}
.modal-maps {
  border: none !important;
  border-radius: 0 !important;
}
.modal-maps button.close {
  background: #0000009c !important;
}
.modal-maps #mapLocalization {
  /*height: 238px;*/
  width: 100%;
}

#mapLocalization {
  /*height: calc(100vh - 117px);*/
  margin: 0;
  height: 100vh;
}
.modal-maps .vueperslide__content {
  padding-left: 10px;
}
.mapAddress {
  height: 48px;
  width: 300px;
  position: absolute;
  top: 150px;
  left: 150px;
  z-index: 999;
  left: 50%;
  margin-left: -150px;
  top: 68px;
  margin-top: -50px;
}
#mapLocation {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  height: 48px;
  padding: 14px;
  border-radius: 0.5em;
  background-color: #fff;
  font-weight: bold;
  border-color: rgb(253, 253, 253);
}

.suggestion-wrapper {
  position: absolute;
  top: 64px;
  z-index: 99;
  background: #fff;
  width: 300px;
  margin-left: -150px;
  left: 50%;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  padding-top: 12px;
  padding-bottom: 8px;
}

.suggestion-wrapper .suggestion-item {
  border-bottom: 1px solid rgba(223, 223, 227, 0.8);
  cursor: pointer;
}

.suggestion-wrapper .suggestion-item:last-of-type {
  border-bottom: none;
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  #mapLocalization {
    /*height: calc(100vh - 177px);*/
    height: 100vh;
  }
  .modal-maps .vueperslide__content {
    padding-left: 30px;
  }
  .suggestion-wrapper {
    width: 748px;
    margin-left: -374px;
  }
  .mapAddress {
    width: 748px;
    margin-left: -374px;
  }
}

.modal-maps .vueperslides__inner {
  width: 100%;
}

.modal-maps .vueperslide {
  border: 1px solid rgba(88, 84, 84, 0.19);
  border-radius: 4px;
  width: 80% !important;
}
.modal-maps .vueperslide--active {
  border-top: 2px solid red;
}

.modal-maps .vueperslide--active {
  margin-right: 10px;
}
.modal-maps .vueperslide--active.margin-left-10 {
  margin-left: 20%;
}
.modal-maps .vueperslide--active.margin-left-20 {
  margin-left: 40%;
}
.modal-maps .vueperslide--active.margin-left-30 {
  margin-left: 60%;
}
.modal-maps .vueperslide--active.margin-left-40 {
  margin-left: 80%;
}
.modal-maps .vueperslide--active.margin-left-50 {
  margin-left: 100%;
}

/* .v--modal-box.v--modal {
  width: 100% !important;
  max-width: none !important;  
  margin: auto !important;
} */

.carousel-control.left {
  top: 10px;
  margin-left: 10px !important;
  z-index: 1000;
}

.carousel-control.right {
  top: 10px;
  z-index: 1000;
  margin-right: 10px !important;
}
.carousel-control {
  width: 10px;
  color: #70a401;
  opacity: 1;
}
.map-input-shadow {
  -webkit-box-shadow: 0px 0px 24px -2px rgba(179, 179, 179, 1);
  -moz-box-shadow: 0px 0px 24px -2px rgba(179, 179, 179, 1);
  box-shadow: 0px 0px 24px -2px rgba(179, 179, 179, 1);
}

/*style the box*/
.gm-style .gm-style-iw {
  border-radius: 0 !important;
}

.gm-style .gm-style-iw-c {
  padding: 0;
}

.gm-style .gm-style-iw-d {
  box-sizing: content-box;
  overflow: hidden !important;
  text-align: center;
}

.gm-style-iw button[title='Cerrar'] {
  display: none !important;
}
</style>
