<template>
  <div class="row">
    <h2 class="cond caps xs-text-center xs-py-20 xs-relative xs-my-0">
      <span>
        <span>{{ $t('header.findahut') }}</span>
      </span>
    </h2>
    <div class=" mycontainer xs-fill-white p-30">
      <div class=" form-group ">
        <div class="">{{ $t('content.findahut') }}</div>
      </div>
      <div class=" form-group">
        <div class="">
          <div
            class="xs-flex xs-items-end xs-border xs-border-gray-light xs-border-1 rounded xs-relative xs-z-300 xs-fill-white xs-mb-10"
          >
            <div class="xs-flex-1">
              <input
                style="border-radius: 5px"
                class="search search--hut input xs-border-none xs-py-10 xs-px-10"
                id="address-autocomplete"
                data-synth="address-input"
                name="address"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
                :placeholder="$t('placeholder.input.findahut')"
                value=""
                type="search"
                v-model="searchAutoComplete"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div style="height: 30px;">
          <button
            data-item-id="localisation-auto"
            data-item-type="tool"
            data-item-position="4"
            v-if="searchAutoComplete.length <= 0"
            class="trigger-location use-current-location flex xs-items-center pull-left"
            v-on:click="useMyLocation"
            style="color: #4a90e2"
          >
            <i class="icon-geo mr-10"></i>
            {{ $t('using_my_local_carryout') }}
          </button>
        </div>

        <div class="clearfix"></div>

        <div
          class="container-outlets flex flex-wrap "
          v-if="outletList.length > 0"
        >
          <a
            v-for="(outlet, index) in outletsByType"
            :key="index"
            class="mb-10 w-full md:w-1/2 text-left pl-15 py-10 typo-l5 findahut-outlet"
            :attr-outlet-id="outlet.id"
            v-bind:href="'/hut/' + outlet.code + '-'+ outlet.name.replace(/ /g,'+')"
          >
            <span class="bold">{{ outlet.name }}</span
            ><span v-if="outlet.distance > 0" class="t12">
              ( {{ formatNum(outlet.distance) }} kilometers )</span
            >
            <br />
            <p>{{ outlet.address }}</p>

            <div>
              <p>{{ $t('service_at_this_hut') }}</p>
            </div>
            <div class="float-l">
              <div
                v-if="outlet.disposition.indexOf('R') > -1"
                class="service-at-hut"
              >
                <i class="fa fa-cutlery text-red t17 pr-7"></i>
                <p>{{ $t('order_type_dine') }}</p>
              </div>
              <div
                v-if="outlet.disposition.indexOf('D') > -1"
                class="service-at-hut"
              >
                <i class="fa fa-taxi text-red t17 pr-7"></i>
                <p>{{ $t('delivery') }}</p>
              </div>
              <div
                v-if="outlet.disposition.indexOf('C') > -1"
                class="service-at-hut mr-l-c"
              >
                <img
                  style="width: 30px;"
                  src="https://deo515rx9pz7c.cloudfront.net/fcds/icons/red-pizzabox.png"
                  class="carryout-icon"
                />
                <p>{{ $t('carryout') }}</p>
              </div>
            </div>
            <div v-show="checkType == 'SelfCollect'" class=" float-r">
              <a
                @click.prevent="clickChosenOutlet(outlet)"
                class="btn btn-success"
                >Select</a
              >
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModalMap from '../popup/ModalMaps.vue'
import ConfirmOrderAdvance from '../popup/ConfirmOrderAdvance.vue'
export default {
  created() {
    // this.findHut()
    let key = this.$const.GOOGLE_MAP_API_KEY
    this.checkPosition(this)
    this.loadScriptMap(key)
  },
  data() {
    return {
      ListFindAHut: ['DineIn', 'SelfCollect'],
      checkType: 'DineIn',
      searchAutoComplete: '',
      addressList: [],
      searchLocation: false,
      locationCenter: { lat: 0, lng: 0 },
      orderType: 'R',
      outletList: [],
      highlight: null,
      result: {},
      mapOpen: false,
      searchText: '',
      geocoder: {}
    }
  },
  watch: {
    searchAutoComplete: function(newValue, oldValue) {
      if (!this.searchLocation) {
        let key = this.$const.GOOGLE_MAP_API_KEY
        this.loadScriptMap(key)
        this.initAutoCompleteSearch()
      }
      if (!this.searchAutoComplete) {
        this.fetchOutletsFromApi(this)
        this.is_not_fount = false
      }
    }
  },
  computed: {
    outletsByType: function() {
      let vm = this
      return vm.outletList
    }
  },
  mounted() {
    this.eventTrackingScreenLoad(this, {
      screen: { name: 'home.fin-a-hut', type: 'home' }
    })
    this.$root.$on('activeCollectionMap', () => {
      this.clickActiveCollection()
      this.searchLocation = true
      this.searchAutoComplete = this.addressSelected
      this.getLatLog(this.addressSelected)
    })
    this.fetchOutletsFromApi(this)
  },
  methods: {
    findHut() {
      var vm = this
      this.axios.apiOutlet.getOutLetByCode(
        { code: this.$route.params.outletid },
        function(response) {
          vm.outlet = response.data.data.item
          if (Object.keys(vm.outlet).length > 0 <= 0) {
            vm.$notify({
              type: 'warn',
              title: vm.$t('localization.not_store')
            })
          } else {
            let data = {}
            data.prefectureName = response.data.data.item.name
            data.cityName = ''
            data.villeageName = ''
            data.st = ''
            data.lat = response.data.data.item.lat
            data.long = response.data.data.item.long
            data.postalCode = response.data.data.item.zip_code
            data.locationName = ''
            data.outletAddress = response.data.data.item.address
            data.orderType = 'R'
            data.outletId = response.data.data.item.id
            data.outletName = response.data.data.item.name
            data.outletCode = response.data.data.item.code
            data.addressCode = 'R'
            data.advanceOrder = '0'
            data.tier = response.data.data.item.tier
            data.tableid = vm.$route.params.tableid
            vm.$disposition.set(vm, data)
            vm.$session.remove('cart')
            vm.$root.$emit('onProcessChangeCategory')
          }
        },
        function(error) {
          console.log(`error: ${error}`)
          vm.$notify({
            type: 'warn',
            title: vm.$t('localization.notfound')
          })
          // vm.$router.push('/')
        }
      )
    },
    active(type) {
      this.orderType = 'R'
      if (type == 'SelfCollect') {
        this.orderType = 'C'
      }
      this.checkType = type
      let input = document.getElementById('address-autocomplete')
      this.message = ''
      this.addressList = []
      this.outletList = []
      // this.searchByAddress()
      if (this.searchAutoComplete) {
        this.searchByLocation(this.locationCenter.lat, this.locationCenter.lng)
      }
    },
    loadScriptMap(key) {
      (function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src =
          'https://maps.googleapis.com/maps/api/js?v=3&key=' +
          key +
          '&libraries=places&language=en&region=PH'
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'google-maps-jssdk')
    },
    /**
     * Using your location for show google map
     * */
    useMyLocation() {
      var vm = this
      vm.searchLocation = true
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
          vm.getAddressByLatLog({
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude)
          })
          vm.searchByLocation(
            position.coords.latitude,
            position.coords.longitude
          )
          vm.locationCenter.lat = position.coords.latitude
          vm.locationCenter.lng = position.coords.longitude
        })
      }
    },
    /**
     * Get lat long from address
     * */
    getAddressByLatLog(latLong) {
      var vm = this
      vm.locationCenter.lat = latLong.lat
      vm.locationCenter.lng = latLong.lng
      this.initGeocoder()
      vm.searchLocation = true
      this.geocoder.geocode({ location: latLong }, function(results, status) {
        if (status === 'OK') {
          if (results[0]) {
            vm.searchAutoComplete = results[0].formatted_address
          }
        }
      })
    },
    initGeocoder() {
      if (typeof google !== 'undefined') {
        this.geocoder = new google.maps.Geocoder()
      }
    },
    /**
     * Chosen a address
     * */
    clickChosenOutlet(outlet) {
      this.result = {}
      if (outlet) {
        var vm = this
        vm.$bus.$emit('loading', 'block')
        vm.result.lat = outlet.lat
        vm.result.long = outlet.long
        vm.result.outletAddress = outlet.address
        vm.result.orderType = vm.$const.CARRYOUT
        vm.result.outletId = outlet.id
        vm.result.outletName = outlet.name
        vm.result.outletCode = outlet.code
        vm.result.tier = outlet.tier
        vm.checkValidOutlet(0)
      }
    },
    initAutoCompleteSearch() {
      var vm = this
      let element = document.getElementById('address-autocomplete')
      if (
        typeof google !== 'undefined' &&
        typeof element != 'undefined' &&
        element != null &&
        !this.addressAutoComplete
      ) {
        this.addressAutoComplete = new google.maps.places.Autocomplete(
          document.getElementById('address-autocomplete'),
          {
            types: ['geocode'],
            componentRestrictions: { country: this.$const.COUNTRY_CODE }
          }
        )
        this.addressAutoComplete.addListener('place_changed', () => {
          if (this.addressAutoComplete) {
            let place = this.addressAutoComplete.getPlace()
            vm.searchAutoComplete = document.getElementById(
              'address-autocomplete'
            ).value
            if (!place) {
              return
            }
            let lat = place.geometry.location.lat()
            let lng = place.geometry.location.lng()
            let myLatLng = lat + ',' + lng
            let objAddress = {
              address: 'My Location',
              latlng: myLatLng,
              lat: lat,
              lng: lng,
              place_id: place.place_id
            }
            vm.getDataFromGG(objAddress)
          }
        })
      }
    },
    /**
     * Search stores by free text
     * */
    searchByAddress() {
      var ref = this
      this.addressList = []
      this.outletList = []
      if (!this.searchAutoComplete) {
        return false
      }
      this.loadingFreeText = true
      ref.message = ''
      this.axios.apiOutlet.getNextLevelData(
        {
          address: this.searchAutoComplete,
          order_type: this.orderType,
          limit: 10,
          client_id: 3
        },
        function(response) {
          // console.log('SUCCESS')
          // console.log(response.data)
          ref.addressList = response.data.data.items
          if (ref.addressList.length <= 0 && !ref.is_not_fount) {
            ref.is_not_fount = true
            ref.$notify({
              type: 'warn',
              title:
                ref.$t('localization.stores_404') +
                ' ' +
                ref.$t(ref.orderType.toLowerCase()).toLowerCase()
            })
          }
          ref.loadingFreeText = false
        },
        function(error) {
          // console.log('ERROR')
          // console.log(error)
          ref.$notify({
            type: 'warn',
            title:
              ref.$t('localization.stores_404') +
              ' ' +
              ref.$t(ref.orderType.toLowerCase()).toLowerCase()
          })
          ref.loadingFreeText = false
        }
      )
    },
    /**
     * Search stores by location
     * */
    searchByLocation(lat, lng) {
      this.locationCenter.lat = lat
      this.locationCenter.lng = lng
      this.mode = 'location'
      this.outletList = []
      this.addressList = []
      if (!lng || !lat) {
        return false
      }
      let val = lng + ',' + lat
      let data = { location: val }
      var vm = this
      vm.message = ''
      this.fetchOutletsFromApi(vm, data)
    },
    fetchOutletsFromApi(vm, data) {
      this.axios.apiOutlet.checkLocation(
        data || { limit: 500 },
        function(response) {
          vm.searchLocation = false
          vm.outletList = response.data.data.items
          if (vm.outletList.length <= 0) {
            vm.message =
              vm.$t('localization.stores_404') +
              ' ' +
              vm.$t(vm.orderType.toLowerCase()).toLowerCase()
          }
          if (!data) {
            vm.outletList = vm.outletList.sort((a, b) =>
              a.name > b.name ? 1 : -1
            )
          }
        },
        function(error) {
          vm.searchLocation = false
          vm.message =
            vm.$t('localization.stores_404') +
            ' ' +
            vm.$t(vm.orderType.toLowerCase()).toLowerCase()
        }
      )
    },
    onclickOutletAddressItem(outlet) {
      if (outlet) {
        this.searchLocation = true
        this.searchAutoComplete = outlet.address
        this.getLatLog(outlet.address)
      }
    },
    /**
     * Get data from google
     * */
    getDataFromGG(address) {
      var vm = this
      if (address.address.length <= 0) {
        return false
      }
      if (
        typeof address.lat !== 'undefined' &&
        typeof address.lng !== 'undefined'
      ) {
        vm.searchByLocation(address.lat, address.lng)
        return false
      }
      var data = {}
      data.address = address.address || ''
      data.sensor = false
      data.key = this.$const.GOOGLE_MAP_API_KEY
      data.types = 'address'
      data.components = vm.$const.COUNTRY_CODE_COMPONENT // 'country:jp'
      if (
        typeof address.place_id !== 'undefined' &&
        address.place_id.length > 0
      ) {
        delete data.address
        delete data.types
        delete data.components
        delete data.latlng
        data.place_id = address.place_id
      } else if (
        typeof address.latlng !== 'undefined' &&
        address.latlng.length > 0
      ) {
        delete data.address
        delete data.types
        delete data.components
        data.latlng = address.latlng
      }
      delete this.axios.defaults.headers.common['Lang']
      delete this.axios.defaults.headers.common['Client']
      this.axios
        .get('https://maps.googleapis.com/maps/api/geocode/json', {
          params: data
        })
        .then(function(response) {
          vm.setData(response.data.results)
          let lat = vm.result.lat
          let lng = vm.result.long
          if (lat != '' && lng != '') {
            vm.searchByLocation(lat, lng)
          }
        })
        .catch(function(error) {
          console.log(error)
        })
        .then(function() {
          // always executed
          vm.axios.defaults.headers.common['Lang'] = vm.$i18n.locale
          vm.axios.defaults.headers.common['Client'] = vm.$session.set('client')
        })
    },
    /**
     * Get lat long from address
     * */
    getLatLog(address) {
      var vm = this
      let geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address: address }, function(results, status) {
        if (status === 'OK') {
          let lat = results[0].geometry.location.lat()
          let lng = results[0].geometry.location.lng()
          vm.searchByLocation(lat, lng)
        }
      })
    },
    resetMap() {
      let vm = this
      for (let i = 0; i < vm.markers.length; i++) {
        vm.markers[i].setMap(null)
      }
      vm.markers = []
    },
    /**
     * Checking outlet is available
     * */
    checkValidOutlet(order_now) {
      // check time outlet
      var vm = this
      let data = {
        order_type: this.orderType.charAt(0),
        order_time: this.convertDateTimeToUTC(
          this.$disposition.getByKey(this, 'collectionTimeText', '')
        ),
        advance_order: this.$disposition.getByKey(this, 'advanceOrder', 0) || 0,
        outlet_id: this.result.outletId,
        address: this.result.outletAddress,
        zip_code: this.result.zipCode
      }
      if (order_now == 1) {
        (data.order_time = this.$moment()
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')),
          (data.advance_order = 1)
      }
      this.axios.apiOutlet.checkOutletAvailable(
        data,
        function(response) {
          response = response.data
          if (response.status) {
            let data = vm.$disposition.get(vm)
            response = response.data.item
            // set data
            data.prefectureName = vm.result.prefectureName || ''
            data.cityName = vm.result.cityName || ''
            data.villeageName = vm.result.villeageName || ''
            data.st = vm.result.st || ''
            data.lat = response.lat
            data.long = response.long
            data.postalCode = response.zip
            data.locationName = vm.result.locationName
            data.outletAddress = response.address
            data.orderType = vm.result.orderType
            data.outletId = response.id
            data.outletName = response.name
            data.outletCode = response.code
            data.advanceOrder =
              vm.$disposition.getByKey(vm, 'advanceOrder', 0) || 0
            data.tier = response.tier
            vm.$disposition.set(vm, data)
            vm.$router.push({
              name: 'category',
              params: { slug: vm.$const.MENU_DEFAULT }
            })
            vm.$root.$emit('onReInitHeader')
            vm.showHideMap(true)
          } else if (response.message && response.message != '') {
            vm.$notify({
              type: 'warn',
              title: response.message
            })
          }
        },
        function(error) {
          let message = false
          if (error.message) {
            message = error.message
          }
          vm.$modal.show(ConfirmOrderAdvance, {
            message: message,
            outletId: vm.result.outletId
          })
        }
      )
    }
  }
}
</script>

<style scoped>
.mr-l-c {
  margin-left: 15px !important;
}
.cond {
  font-family: 'Sharp Sans', Open Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
}
.caps {
  ext-transform: uppercase !important;
}
.xs-relative {
  position: relative !important;
}
.xs-text-center {
  text-align: center !important;
}
.xs-py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.xs-my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.mycontainer {
  max-width: 960px;
  margin: 0 auto;
  padding: 0;
}
.p-30 {
  padding: 30px;
}
.xs-fill-white {
  background-color: #fff !important;
}
.form-group {
  margin-bottom: 15px;
}
.text-center {
  text-align: center;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 2;
}
a {
  color: #000;
}
.service-at-hut {
  margin-left: 20px;
}
.service-at-hut i,
img,
p {
  display: inline;
}
.float-r {
  float: right;
}
</style>
